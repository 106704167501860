exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-relatie-event-js": () => import("./../../../src/pages/relatie-event.js" /* webpackChunkName: "component---src-pages-relatie-event-js" */),
  "component---src-pages-sustainability-scan-js": () => import("./../../../src/pages/sustainability-scan.js" /* webpackChunkName: "component---src-pages-sustainability-scan-js" */),
  "component---src-pages-vacatures-js": () => import("./../../../src/pages/vacatures.js" /* webpackChunkName: "component---src-pages-vacatures-js" */),
  "component---src-templates-news-article-list-js": () => import("./../../../src/templates/news-article-list.js" /* webpackChunkName: "component---src-templates-news-article-list-js" */),
  "component---src-templates-news-article-post-js": () => import("./../../../src/templates/news-article-post.js" /* webpackChunkName: "component---src-templates-news-article-post-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/page-post.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-vacancy-post-js": () => import("./../../../src/templates/vacancy-post.js" /* webpackChunkName: "component---src-templates-vacancy-post-js" */)
}

